import React from 'react';
import * as R from 'ramda';

import ReplenishmentForm from '@atom/components/common/requests/viewRequest/sddotView/ReplenishmentForm';
import { TextField } from '@atom/mui';
import colors from '@atom/styles/colors';
import {
  AssetRequestCreateInput,
  ReplenishmentCreateInput,
} from '@atom/types/assetRequest';
import { AssetBudget } from '@atom/types/inventory';

import './sddotCreate.css';

const styles = {
  label: {
    color: colors.neutral.dim,
  },
  orderedInput: {
    width: '13.75rem',
  },
};

interface Props {
  budget: AssetBudget;
  createInputs: AssetRequestCreateInput;
  updateCreateInput: (
    value: any,
    property: keyof AssetRequestCreateInput,
  ) => void;
}

const SDDOTReplenishmentCreate = ({
  budget,
  createInputs,
  updateCreateInput,
}: Props) => {
  const totalQuantityReceived =
    createInputs?.replenishment?.quantityReceived || 0;
  const totalQuantityRemaining =
    R.pathOr(0, ['quantityOrdered'], createInputs) - totalQuantityReceived;

  const updateReplenishmentInput = (
    value: any,
    property: keyof ReplenishmentCreateInput,
  ) => {
    const updatedReplenishment = {
      ...createInputs.replenishment,
      [property]: value,
    };

    updateCreateInput(updatedReplenishment, 'replenishment');
  };

  const numberUpdate = (
    value: any,
    property: keyof AssetRequestCreateInput,
  ) => {
    const updatedValue = value === '' ? null : Math.abs(Number(value));
    updateCreateInput(updatedValue, property);
  };

  return (
    <>
      <div styleName="input-row">
        <TextField
          InputLabelProps={{ style: styles.label }}
          style={styles.orderedInput}
          type="number"
          value={createInputs?.quantityOrdered}
          label={`Total Quantity Ordered (${budget?.unit})`}
          onChange={event =>
            numberUpdate(event.target.value, 'quantityOrdered')
          }
        />
        <div styleName="data-cell">
          <div styleName="data-cell-title">{`Total Quantity Received (${budget?.unit})`}</div>
          <div>{totalQuantityReceived || '-'}</div>
        </div>
        <div styleName="data-cell">
          <div styleName="data-cell-title">{`Total Remaining Quantity on Order (${budget?.unit})`}</div>
          <div>{totalQuantityRemaining || '-'}</div>
        </div>
      </div>
      <div styleName="input-row">
        <ReplenishmentForm
          assetUnit={budget?.unit}
          totalOrdered={createInputs.quantityOrdered}
          replenishmentInputs={createInputs.replenishment}
          updateReplenishmentInput={updateReplenishmentInput}
        />
      </div>
    </>
  );
};

export default SDDOTReplenishmentCreate;
